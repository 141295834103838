// import logo from './logo.svg';
import './App.css';
import Home from "./container/pages/Home";


function App() {
  return (
    <Home/>
  );
}

export default App;
